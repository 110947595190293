// 
// 
// flickity.scss
//
//

@import "CSS:../plugins/flatpickr.min";

.flatpickr-calendar {
  width: auto;
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 0;
  -webkit-animation: none;
  animation: none;
  direction: ltr;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  border-radius: 5px;
  position: absolute;
  box-sizing: border-box;
  touch-action: manipulation;
  background: #fff;
  box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08);
  max-width: 100%;
}

.flatpickr-days {
  width: auto;
}

.dayContainer {
  width: 100%;
  max-width: 100%;
  min-width: auto;
}

.flatpickr-months{
  margin: $spacer/2 0;
  .flatpickr-month, .flatpickr-next-month, .flatpickr-prev-month{
    height: auto;
    position: relative;
    &:hover{
      svg{
        fill: $primary;
      }
    }
  }
  .flatpickr-month{
    color: $body-color;
  }
}

.flatpickr-current-month{
  span.cur-month{
    font-weight: $font-weight-bold;
    &:hover{
      background: rgba($primary,.15);
    }
  }
}

.numInputWrapper{
  &:hover{
    background: rgba($primary,.15);
  }
}

.flatpickr-day{
  font-weight: 500;
  color: $body-color;
  height: 50px;
  display: table-cell;
  max-width: none;
  line-height: 24px;
  vertical-align: middle;
  border: 1px solid white;
  border-radius: 0;
  opacity: 0.8;

  &.today{
    border-color: $primary;
    &:hover{
      background: $primary;
      border-color: $primary;
    }
  }
  &:hover{
    background: rgba($primary,.1);
    border-color: rgba(0,0,0,0);
    opacity: 1;
  }

  &>a {
    display: block;
  }

  &.disabled,
  &.disabled:hover {
    cursor: default;
  }

  &:not(.disabled).busy {
    background-color: #d9534f;
    color: white;
    pointer-events: none;
    line-height: 48px !important;
  }

  &:not(.disabled) .available,
  &:not(.disabled) .available:hover {
    background-color: #02B875;
    color: white;
  }
  & .price {
    display: block;
    font-size: xx-small;
  }
}

span.flatpickr-weekday{
  color: $body-color;
  font-weight: 500;
}

.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay{
  background: $primary;
  border-color: $primary;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)){
  box-shadow: -10px 0 0 $primary;
}

.flatpickr-day.selected.startRange, .flatpickr-day.startRange.startRange, .flatpickr-day.endRange.startRange{
  border-radius: $border-radius 0 0 $border-radius;
}

.flatpickr-day.selected.endRange, .flatpickr-day.startRange.endRange, .flatpickr-day.endRange.endRange{
  border-radius: 0 $border-radius $border-radius 0;
}