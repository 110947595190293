// 
// 
// image-gallery.scss
//
//


// Gallery Triplet
//
// An arrangemnent of three photos, 1 portrait, 2 landscape placed on a .row


.image-gallery{
  img{
    max-width: 100%;
  }
}

article{
  .image-gallery{
    margin: $spacer*3 0;
  }
}