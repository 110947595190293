//
//
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.
[placeholder]:empty:before {
  content: attr(placeholder);
  color: #ddd;
  cursor: text;
}

[placeholder]:empty:focus:before {
  content: ' ';
}

.inactive {
  pointer-events: none;
  opacity: 0.5;
}

.dropdown-menu {
  z-index: 1021;
}

.text-shadow {
  text-shadow: 1px 1px 5px #000000;
}

.freelance-logo {
  display: inline-block;
  width: 152px;
  height: 50px;
  background: url("/img/freelance_logo_sprite.png") no-repeat;
  background-size: 152px;
}
.freelance-logo[title="freelance.ru"] {
  background-position: 0 0;
}
.freelance-logo[title="freelansim.ru"] {
  background-position: 0 -50px;
}
.freelance-logo[title="etxt.ru"] {
  background-position: 0 -100px;
}
.freelance-logo[title="weblancer.net"] {
  background-position: 0 -150px;
}
.freelance-logo[title="fl.ru"] {
  background-position: 0 -200px;
}

.job-logo {
  display: inline-block;
  width: 152px;
  height: 50px;
  background: url("/img/job_logo_sprite.png") no-repeat;
  background-size: 152px;
}
.job-logo[title="nur.kz"] {
  background-position: 0 0;
}
.job[title="yandex.ru"] {
  background-position: 0 -50px;
}

.excursions-logo {
  display: inline-block;
  width: 152px;
  height: 50px;
  background: url("/img/excursions_logo_sprite.png") no-repeat;
  background-size: 152px;
}
.excursions-logo[title="sputnik8.com"] {
  background-position: 0 0;
}
.excursions-logo[title="experience.tripster.ru"] {
  background-position: 0 -50px;
}
.excursions-logo[title="weatlas.com"] {
  background-position: 0 -100px;
}
.excursions-logo[title="localie.co"] {
  background-position: 0 -150px;
}
.excursions-logo[title="georgia4travel.ru"] {
  background-position: 0 -200px;
}

.footer-long {
  padding: 4.5rem 0;
}



.shine-animation {
  -webkit-mask-image: linear-gradient(-75deg, rgba(0, 0, 0, 0.6) 30%, #000 50%, rgba(0, 0, 0, 0.6) 70%);
  -webkit-mask-size: 200%;
  animation: shine 4s infinite;
}

@-webkit-keyframes shine {
  from {
    -webkit-mask-position: 150%;
  }
  to {
    -webkit-mask-position: -50%;
  }
}


#dzen-logo {
  svg {
    fill: #adb5bd;
  }
  &:hover svg {
    fill: #ffffff;
  }
}



#scrollspy-navbar {  // Safari and Chrome
  .nav-pills {
    white-space: nowrap;
    overflow-y: hidden;
    flex-wrap: nowrap;
    -ms-overflow-style: none;  // IE 10+
    overflow: -moz-scrollbars-none;  // Firefox

    &::-webkit-scrollbar {
      display: none; // Chrome, Safari
    }
    .nav-link.active, .nav-pills .show > .nav-link {
      color: crimson;
      background: none;
    }
  }
  &.text-light {
    .nav-link.active, .nav-pills .show > .nav-link {
      color: white;
    }
  }
}

.navbar-brand {
  font-size: inherit;
  position: relative;
  &:after {
    content: 'β';
    font-size: small;
    position: absolute;
    bottom: 0;
  }
}

.nav-link {
  cursor: pointer;
}

tbody tr th {
  font-weight: bold;
}

blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
  border-left: 5px solid #eee;
}

.text-underline {
  text-decoration: underline;
}

#article-content img,
#article-content video,
#article-content iframe,
#article-content .iframe_wrap {
  max-width: 100%;
  vertical-align: top;
}
#article-content .iframe_wrap {
  max-width: 100%;
  vertical-align: top;
  position: relative;
}
#article-content .iframe_wrap iframe {
  position: absolute;
  width: 1px;
  height: 1px;
  min-width: 100%;
  min-height: 100%;
  *width: 100%;
  *height: 100%;
  top: 0;
  left: 0;
}

#article-content figure {
  cursor: pointer;
  margin: inherit;
}

figure {
  padding: 0;
  text-align: center;
  position: relative;
}

figure.center {
  margin: 0 auto;
}

figure.float-left {
  margin: 0 20px 0 0;
}

figure.float-right {
  margin: 0 0 0 20px;
}

figure > figcaption {
  text-align: center;
  font-size: 0.75rem;
  color: #6c757d;
  margin: 0.5rem 0;
  //white-space: pre;
}

.figure-caption {
  white-space: normal;
}

.figure_wrapper {
  position: relative;
}

.is-bg {
  color: #ffffff;
  //text-shadow: 1px 1px 5px #000000;
  .display-4, .h6, .text-muted {
    color: #ffffff !important;
    text-shadow: 1px 1px 5px #000000;
  }
  .details {
    background: rgba(0, 0, 0, 0.5);
  }
}

#quill-form .ql-blank {
  min-height: 200px;
}


#article-content figure .counters {
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  padding: 5px 10px;
}

#article-content figure:hover {
  .counters {
    display: inline-block;
  }
}

.counters {
  font-size: small;
  .likes, .comments {
    cursor: pointer;
    user-select: none;
    color: #ffffff;
    &:before {
      color: #ffffff;
      font-size: large;
      margin-right: 3px;
      vertical-align: middle;
    }
  }
  .comments {
    margin-left: 10px;
  }
}

#figure-modal {
  .modal-dialog {
    max-width: 960px;
    pointer-events: inherit;
  }
  .btn-close {
    position: absolute;
    right: 0;
    top: 0;
    line-height: 1;
    z-index: 1;
  }
  .btn-prev, .btn-next, .btn-close {
    cursor: pointer;
    opacity: 0.5;
    font-size: xx-large;
    color: #ffffff;
  }
  .btn-prev:hover, .btn-next:hover, .btn-close:hover {
    opacity: 1;
  }
  .btn-prev.disabled, .btn-next.disabled {
    opacity: 0;
    cursor: inherit;
  }
  .likes:before, .comments:before {
    color: inherit;
  }
  .likes, .comments {
    &.active {
      color: #ff3347;
      &:before {
        color: #ff3347;
        -webkit-animation: heart_animation 0.2s ease-out;
        -o-animation: heart_animation 0.2s ease-out;
        animation: heart_animation 0.2s ease-out;
      }
    }
  }
}

.media-body .title {
  color: #343a40;
  display: block;
}

.odometer-inside {
  display: inline-block;
}

.odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner, .odometer.odometer-theme-minimal.odometer-animating-up .odometer-ribbon-inner,
.odometer.odometer-auto-theme.odometer-animating-down.odometer-animating .odometer-ribbon-inner, .odometer.odometer-theme-minimal.odometer-animating-down.odometer-animating .odometer-ribbon-inner {
  transition-duration: 0.2s;
}

@-o-keyframes heart_animation {
  0% {
    -o-transform: scale(1);
    transform: scale(1)
  }

  50% {
    -o-transform: scale(1.15);
    transform: scale(1.15)
  }

  to {
    -o-transform: scale(1);
    transform: scale(1)
  }
}

@keyframes heart_animation {
  0% {
    -o-transform: scale(1);
    transform: scale(1)
  }

  50% {
    -o-transform: scale(1.15);
    transform: scale(1.15)
  }

  to {
    -o-transform: scale(1);
    transform: scale(1)
  }
}


.share-social {
  .icon {
    display: inline-block;
    width: 48px;
    height: 48px;
    opacity: 0.9;
    border-radius: 50%;
    &:hover {
      opacity: 1;
    }
    &.vk {
      background: url("/img/social/vk.svg") #4D76A1;
    }
    &.fb {
      background: url("/img/social/facebook.svg") #3B5998;
    }
    &.tw {
      background: url("/img/social/twitter.svg") #26A6D1;
    }
  }
  &-square {
    .icon {
      border-radius: 0;
    }
  }
  &-small {
    font-size: 0;
    line-height: 2.5rem;
    .list-inline-item {
      margin: 0 0 0 0.5rem !important;
    }
    .icon {
      width: 24px;
      height: 24px;
      vertical-align: middle;
    }
  }
}


.bootstrap-tagsinput {
  width: 100%;
  padding: 5px 6px;
}

.feature-list {
  .card {
    overflow: hidden;
    &.with-image {
      text-shadow: 1px 1px 5px #000000;
      .card-body, .card-body .card-title, .card-body small, .card-footer {
        color: #ffffff !important;
      }
    }
  }
  .card-thumbnail {
    padding: 0 0 66.6% 0;
    background-size: cover;
    width: 100%;
    display: block;
    position: relative;
  }
  .card-body, .card-footer {
    z-index: 1;
  }
  .card-more {
    text-align: center;
    display: table;
    vertical-align: middle;
    height: 100%;
    > span {
      display: table-cell;
      vertical-align: middle;
    }
  }
}

#electricity {
  .tooltip-inner {
    padding: 2px;
  }
  .tooltip.show {
    opacity: 1;
  }
  .tooltip img {
    width: 92px;
  }
}

ul.auth-clients {
  padding: 0;
}

#top-menu.text-white a.show-modal {
  color: white;
  &:hover {
    text-decoration: underline;
  }
}

.badge-indicator.small {
  width: 4px;
  height: 4px;
}



.omnisearch {
  width: 100%;
  margin-top: 1.5rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  opacity: 0;
  background-color: transparent;
  pointer-events: none;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: opacity 0.15s, -webkit-transform 0.3s;
  transition: opacity 0.15s, -webkit-transform 0.3s;
  -o-transition: transform 0.3s, opacity 0.15s;
  transition: transform 0.3s, opacity 0.15s;
  transition: transform 0.3s, opacity 0.15s, -webkit-transform 0.3s;
}
@media (prefers-reduced-motion: reduce) {
  .omnisearch {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}
.omnisearch.show {
  opacity: 1;
  //-webkit-transform: translateY(50%);
  //-ms-transform: translateY(50%);
  //transform: translateY(50%);
}
.omnisearch .container {
  position: relative;
  height: 100%;
}
.omnisearch .omnisearch-form {
  display: block;
  position: relative;
  z-index: 700;
  background: #FFF;
  border-radius: 0.375rem;
  width: 680px;
  margin: auto;
}
.omnisearch .omnisearch-form .input-group-text {
  font-size: 1.25rem;
  background: transparent;
  border: 0;
}
.omnisearch .omnisearch-form .form-control {
  display: block;
  height: 68px;
  font-size: 1.25rem;
  color: #454545;
  background-color: transparent;
  background-image: none;
  border: 0;
  outline: 0;
  box-shadow: none;
}
.omnisearch .omnisearch-form .form-control::-webkit-input-placeholder {
  color: #C0CCDA;
}
.omnisearch .omnisearch-form .form-control:-ms-input-placeholder {
  color: #C0CCDA;
}
.omnisearch .omnisearch-form .form-control::-ms-input-placeholder {
  color: #C0CCDA;
}
.omnisearch .omnisearch-form .form-control::placeholder {
  color: #C0CCDA;
}
.omnisearch .omnisearch-suggestions {
  width: 680px;
  min-height: 150px;
  padding: 1.5rem;
  background: white;
  margin: auto;
  border-radius: 0.375rem;
  position: relative;
  opacity: 0;
  text-align: left;
  -webkit-transition: opacity 0.3s;
  -o-transition: opacity 0.3s;
  transition: opacity 0.3s;
  -webkit-transition-delay: 0.21s;
  -o-transition-delay: 0.21s;
  transition-delay: 0.21s;
}
@media (prefers-reduced-motion: reduce) {
  .omnisearch .omnisearch-suggestions {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}
.omnisearch .omnisearch-suggestions:before {
  background: #FFF;
  -webkit-box-shadow: none;
  box-shadow: none;
  content: "";
  display: block;
  height: 16px;
  width: 16px;
  left: 20px;
  position: absolute;
  bottom: 100%;
  -webkit-transform: rotate(-45deg) translateY(1rem);
  -ms-transform: rotate(-45deg) translateY(1rem);
  transform: rotate(-45deg) translateY(1rem);
  z-index: -5;
  border-radius: 0.2rem;
}
.omnisearch .omnisearch-suggestions .heading {
  color: #8492A6;
}
.omnisearch .omnisearch-suggestions .list-link {
  display: block;
  color: #8492A6;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.omnisearch .omnisearch-suggestions .list-link i {
  margin-right: 0.5rem;
  font-size: 0.75rem;
}

.omnisearch .omnisearch-suggestions .list-link span {
  font-weight: 600;
  color: #3C4858;
}
.omnisearch .omnisearch-suggestions .list-link:hover {
  color: #1559cf;
}
.omnisearch .omnisearch-suggestions .list-link:hover span {
  color: #1559cf;
}
.omnisearch.show .omnisearch-form,
.omnisearch.show .omnisearch-suggestions {
  pointer-events: auto;
}
.omnisearch.show .omnisearch-suggestions {
  opacity: 1;
}
.omnisearch .omnisearch-suggestions.hide {
  opacity: 0;
  pointer-events: none;
}
@media (max-width: 991.98px) {
  .omnisearch .omnisearch-form {
    width: 100%;
  }
  .omnisearch .omnisearch-suggestions {
    width: 100%;
  }
}

.omnisearch-open {
  overflow: hidden;
}


.mask-body {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 1040;
}

.mask-body-dark {
  background: rgba(31, 45, 61, 0.3);
}

.mask-body-light {
  background: rgba(255, 255, 255, 0.3);
}

.editable:hover {
  outline: 4px dashed #efefef;
}

@keyframes wiggle {
  0% { transform: rotate(0deg); }
  80% { transform: rotate(0deg); }
  85% { transform: rotate(5deg); }
  95% { transform: rotate(-5deg); }
  100% { transform: rotate(0deg); }
}

#best-price {
  animation: wiggle 2.5s infinite;
}